.app-container {
    width: 100vw;
    height: 100vh;
}
/* .app-container {
  width: 100%;
  max-width: 80rem;
  background-color: red;
  height: 100px;
} */

:disabled {
  opacity: .8;
}

.custom-spinner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.custom-spinner div.texttransform {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--primary-800);
  font-size: 2vw;
}
.custom-spinner div.texttransform b {
  transform: scaleY(0);
  animation: texttransform_in 1.5s infinite cubic-bezier(0.76, 0, 0.24, 1);
}
.custom-spinner div.texttransform b:nth-child(1) {
  animation-delay: 0.15s;
}
.custom-spinner div.texttransform b:nth-child(2) {
  animation-delay: 0.30s;
}
.custom-spinner div.texttransform b:nth-child(3) {
  animation-delay: 0.45s;
}
.custom-spinner div.texttransform b:nth-child(4) {
  animation-delay: 0.60s;
}
.custom-spinner div.texttransform b:nth-child(5) {
  animation-delay: .75s;
}
.custom-spinner div.texttransform b:nth-child(6) {
  animation-delay: 0.9s;
}
.custom-spinner div.texttransform b:nth-child(7) {
  animation-delay: 1.05s;
}
@keyframes texttransform_in {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0)
  } 25% {
    transform-origin: 50% 100%;
    transform: scaleY(1)
  } 75% {
    transform-origin: 50% 0%;
    transform: scaleY(1)
  } 100% {
    transform-origin: 50% 0%;
    transform: scaleY(0)
  }
}
